import { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import { useDebounce } from 'react-use';
import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { SEARCH_BOX_PAGE_SIZE } from '@hultafors/hultafors/helpers/constants';
import { routes } from '@hultafors/hultafors/helpers/routing';
import { useGlobal } from '@hultafors/hultafors/hooks';
import { HultaforsProduct } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Product } from '../Product/Product';
import { SearchInput } from '../SearchInput/SearchInput';

import {
  AutoCompleteSearchBoxStyled,
  Result,
  ShowMoreButton,
} from './AutoCompleteSearchBox.styled';

interface AutoCompleteSearchBoxProps {
  className?: string;
  isOpen?: boolean;
  toggleMenu(key?: string): void;
  placeholder?: string;
  badgeText?: string;
  premiumText?: string;
  modelsText?: string;
  showMoreText?: string;
  noSearchResult?: string;
  searchLabel?: string;
  clearLabel?: string;
  name?: string;
  passive?: boolean;
}

export const AutoCompleteSearchBox: React.FC<AutoCompleteSearchBoxProps> = ({
  isOpen,
  toggleMenu,
  placeholder = '',
  badgeText = '',
  premiumText = '',
  modelsText = '',
  showMoreText = '',
  noSearchResult = '',
  searchLabel = '',
  clearLabel = '',
  className,
  name = 'q',
  passive,
}) => {
  const MIN_CHARS_BEFORE_SEARCH = 2;
  const { settings, siteLocale } = useGlobal();
  const [term, setTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [, cancel] = useDebounce(
    () => {
      setSearchValue(term);
    },
    300,
    [term],
  );

  const { locale, ...router } = useRouter();

  const { data, isValidating } = useSWR<ParsedProductsApiResponse<HultaforsProduct>>(
    !passive
    && searchValue
    && `/api/searchlist/21?${new URLSearchParams({
        searchValue,
        pageSize: `${SEARCH_BOX_PAGE_SIZE}`,
        includeFilters: 'false',
      ...(locale && locale !== 'com' ? { locale } : {}),
        siteId: siteLocale.parttrapSiteId ?? '',
        market: siteLocale.parttrapMarket ?? '',
        lang: siteLocale.parttrapLanguage ?? '',
      }).toString()}`,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value);
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    if (term.length) {
      cancel();
      const query = new URLSearchParams({ q: term.trim() }).toString();
      router.push(`/${routes.SEARCH}?${query}`);

      clearSearch();
      toggleMenu();
    }
  };

  const clearSearch = () => {
    setTerm('');
    setSearchValue('');
    cancel();
  };

  return (
    <AutoCompleteSearchBoxStyled className={className}>
      <SearchInput
        name={name}
        value={term}
        placeholder={placeholder}
        onSubmit={onSubmit}
        onChange={onChange}
        className={isOpen ? 'OPEN' : 'CLOSE'}
        clear={clearSearch}
        clearLabel={clearLabel}
        searchLabel={searchLabel}
        pattern="[^#_@£$|*/]{1,500}"
        disabled={term.length < MIN_CHARS_BEFORE_SEARCH}
      />
      <Result $empty={!data?.items?.length} $show={!!data?.items}>
        {(data?.items?.length && (
          <Grid
            columnGap={[{ columnGap: 20 }]}
            columns={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 6 }]}
          >
            {data?.items?.map((product, index) => {
              if (index < 6) {
                return (
                  <GridChild
                    key={index}
                    columnSpan={[
                      { columns: 2 },
                      { breakpoint: 'mobileMax', columns: 2 },
                    ]}
                  >
                    <Product
                      product={product}
                      isFavorite
                      inFavorites={false}
                      isSmall
                      badgeText={badgeText}
                      premiumText={premiumText}
                      modelsText={modelsText}
                    />
                  </GridChild>
                );
              }
            })}

            {!!data?.items?.length && (
              <GridChild
                columnSpan={[
                  { columns: 4 },
                  { breakpoint: 'mobileMax', columns: 6 },
                ]}
              >
                <ShowMoreButton center onClick={onSubmit}>
                  {showMoreText}
                </ShowMoreButton>
              </GridChild>
            )}
          </Grid>
        )) || <p>{noSearchResult}</p>}
      </Result>
    </AutoCompleteSearchBoxStyled>
  );
};
